import React from 'react';
import { Typography } from '@material-ui/core';

import Container from 'components/Container';
import Link from 'components/Link';
import PageWrapper from 'components/PageWrapper';
import SectionHeadline from 'components/SectionHeadline';

const NotFoundPage = () => (
	<PageWrapper
		title="Not Found"
		postContent={
			<Container>
				<SectionHeadline>NOT FOUND</SectionHeadline>
				<Typography>
					You have come to a page that doesn't exist. It would be best
					to <Link to="/">go back home</Link>.
				</Typography>
			</Container>
		}
	/>
);

export default NotFoundPage;
